export const fields = [

    { key: 'nama', label: "Nama", _style:'min-width:100px' },
    { key: 'alamat', label: "Alamat", _style:'min-width:100px' },
    { key: "id_sub_bagian", label: "ID Sub Bagian", _style:'min-width:50px'},
    { key: 'deskripsi',label: "Deskripsi", _style:'min-width:100px;' },
    { key: 'id_kualifikasi_profesi', label: "ID Kualifikasi Profesi", _style:'min-width:100px;' },
    { key: 'asosiasi', label: "Asosiasi", _style:'min-width:100px;' },
    { key: 'tgl_cetak_sertifikat', label: "Tgl Cetak Sertifikat", _style:'min-width:100px;' },
    { key: 'provinsi_domisili', label: "Provinsi Domisili", _style:'min-width:100px;' },
    { key: 'kabupaten', label: "Kabupaten", _style:'min-width:100px;' },
    { key: 'provinsi_registrasi', label: "Provinsi Registrasi", _style:'min-width:100px;' },
    { key: "action", label: 'Aksi', _style: 'min-width:75px'}
]
